<div class="flex h-full w-full flex-col">
  <!--  header-->
  <div class="flex h-16 w-full items-center pl-6">
    <mat-icon svgIcon="editorup:Ranking (图表)" class="icon-size-5 text-tertiary-700 mr-2"></mat-icon>
    <span class="text-tertiary-900 text-lg">AI推荐图表</span>
  </div>
  <!--  data list-->
  <div class="h-0 w-full flex-grow p-3" aceScrollbar>
    @for (chart of recommendChartList(); track chart.name) {
      <div
        class="relative mb-3 flex h-[150px] w-full flex-col rounded-lg bg-white"
        (click)="handleSelect($index)"
        [class]="$index === currentSelected() ? 'border-primary border-2 border-solid' : 'light-shadow'"
      >
        <!--        chart preview-->
        <!--        <div class="w-full flex-grow bg-contain bg-center bg-no-repeat" [style.background-image]="'url(' + chart.preview + ')'"> </div>-->
        <div
          class="absolute left-0 top-0 origin-top-left"
          [style.width]="originalChartSize.width + 'px'"
          [style.height]="originalChartSize.height + 'px'"
          [style.transform]="'scale(' + scale.x + ',' + scale.y + ')'"
        >
          <ace-chart [selected]="false" [size]="originalChartSize" [setting]="chart.setting"></ace-chart>
        </div>
        <!--        chart title-->
        <div class="absolute bottom-0 flex h-7 w-full flex-shrink-0 items-center justify-center">
          <span class="text-sm text-gray-500">{{ chart.name }}</span>
        </div>

        <!--        insert button-->
        <button
          class="absolute right-2 top-2 z-10 h-10 min-h-10 w-20 min-w-20 rounded-lg bg-indigo-500"
          (click)="handleInsertChart(chart.id)"
          [hidden]="$index !== currentSelected()"
        >
          <span class="text-base text-white">插入图表</span>
        </button>
      </div>
    }
  </div>
</div>

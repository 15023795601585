import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

import { produce } from 'immer'
import { cloneDeep, get, set } from 'lodash'

import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'

import { AppStore } from '#core/store/app.store'
import { ChartComponent } from '#modules/workspace/components/element/chart/chart.component'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { IChartSetting, ISize } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ExcelData } from '#shared/components'
import { ProjectService } from '#shared/services/project/project.service'
import { checkChartFill } from '#shared/utils/chart'

@Component({
  selector: 'ace-chart-recommend',
  standalone: true,
  imports: [CommonModule, MatIcon, ScrollbarDirective, ChartComponent],
  templateUrl: './chart-recommend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartRecommendComponent {
  onSelected = output()
  appStore = inject(AppStore)
  metaCharts = this.appStore.metaCharts
  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  workspaceService = inject(WorkspaceService)
  data = input<ExcelData[][]>([[]])

  // 记录当前图表中配置项是打开但是在预览时关闭的字段 ，在插入时进行还原
  chartShowKeymap = new Map<number, Array<string>>()

  previewCloseKey = ['title.show', 'legend.show', 'tooltip', 'axis.show', 'label.show']

  recommendChartList = computed(() => {
    const charts = cloneDeep(this.metaCharts())
    charts.forEach(chart => {
      this.chartShowKeymap.set(chart.id, [])
    })
    charts.forEach(chart => (chart.data = [this.data()]))
    return charts.map<{ name: string; setting: IChartSetting; id: number }>((chart, index) => {
      const props = chart.props

      // 关闭预览时不需要的配置
      this.previewCloseKey.forEach(key => {
        set(props, key, false)
      })

      return {
        id: chart.id,
        name: chart.name,
        setting: {
          version: index,
          pipe: chart.pipe,
          data: chart.data,
          props: checkChartFill(props, this.data())
        }
      }
    })
  })
  currentSelected = signal(-1)
  scaleChartSize: ISize = { width: 216, height: 121 }
  originalChartSize: ISize = { width: 700, height: 400 }
  scale = { x: this.scaleChartSize.width / this.originalChartSize.width, y: this.scaleChartSize.height / this.originalChartSize.height }
  handleSelect(index: number) {
    if (index === this.currentSelected()) {
      this.currentSelected.set(-1)
    } else {
      this.currentSelected.set(index)
    }
  }

  handleInsertChart(metaChartId: number) {
    const original = this.metaCharts().find(c => c.id === metaChartId)
    const current = this.recommendChartList().find(c => c.id === metaChartId)
    if (original && current) {
      // 根据关闭的配置，从原始数据中还原
      this.previewCloseKey.forEach(key => {
        set(current, key, get(original, key))
      })
      this.workspaceService.addChart(
        produce(original, draft => {
          draft.data.splice(0, 1, this.data())
          draft.props = current.setting.props
        })
      )
      this.onSelected.emit()
    }
  }
}
